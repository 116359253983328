<template>
  <div class="quotes p-8">
    <div class="mx-auto max-w-7xl space-y-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <h1 class="text-3xl font-semibold ">
            Quotes
          </h1>
          <router-link
            v-if="!error"
            to="/quotes/mapview"
            class="hover-trigger gap-1 text-blue-600 hover:text-blue-700 flex items-center ml-4"
          >
            <span class="pt-1 font-medium">Map View</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
              />
            </svg>
          </router-link>
        </div>

        <div>
          Show:
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hidePending = !hidePending"
            :class="{
              'bg-gray-200': hidePending,
              'bg-orange-500': !hidePending,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >P<span class="xhover-target">ending</span>
          </span>
          <span
            @click="hideAssigned = !hideAssigned"
            :class="{
              'bg-gray-200': hideAssigned,
              'bg-green-500': !hideAssigned,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >A<span class="xhover-target">ssigned</span>
          </span>
          <span
            @click="hideProcessed = !hideProcessed"
            :class="{
              'bg-gray-200': hideProcessed,
              'bg-blue-500': !hideProcessed,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >P<span class="xhover-target">rocessed</span>
          </span>
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hideReviewed = !hideReviewed"
            :class="{
              'bg-gray-200': hideReviewed,
              'bg-purple-500': !hideReviewed,
            }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >R<span class="xhover-target">eviewed</span>
          </span>
          <span
            v-if="user.claims.user_role !== 'estimator'"
            @click="hideEmailed = !hideEmailed"
            :class="{ 'bg-gray-200': hideEmailed, 'bg-gray-500': !hideEmailed }"
            class="ml-1 px-2 rounded-full text-white font-semibold hover-trigger"
            >E<span class="xhover-target">mailed</span>
          </span>
        </div>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <div class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200" id="quote-table">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'sequence'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'sequence')
                    "
                    >Number</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden sequence': sortBy !== 'sequence',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden sequence': sortBy !== 'sequence',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'created'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'created')
                    "
                    >Created</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden created': sortBy == 'sequence',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden created': sortBy == 'sequence',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Assigned To
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Accepted
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Customer
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Location
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="quote in filteredQuotes"
              :key="quote.id"
              @click="goTo(`/quotes/${quote.id}`)"
              class="hover:bg-gray-50"
            >
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span
                  :class="{
                    'px-2 rounded-full bg-orange-500 text-white':
                      quote.quote_status == 'pending',
                    'px-2 rounded-full bg-green-500 text-white':
                      quote.quote_status == 'assigned',
                    'px-2 rounded-full bg-blue-500 text-white':
                      quote.quote_status == 'processed',
                    'px-2 rounded-full bg-purple-500 text-white':
                      quote.quote_status == 'reviewed',
                    'px-2 rounded-full bg-gray-500 text-white':
                      quote.quote_status == 'emailed',
                    'px-2 rounded-full bg-black text-white':
                      quote.quote_status == 'accepted',
                  }"
                  class="font-semibold capitalize"
                >
                  {{ quote.quote_status }}
                </span>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ quote.sequence_number }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ fromSeconds(quote.createdAt?.seconds) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <span v-if="quote.override_price">*</span>
                {{
                  Number(
                    quote.override_price
                      ? quote.override_price
                      : quote.total_price
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ usernameFromId(quote.assigned_to) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ quote.accepted ? "&check;" : "" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div>{{ quote.customer?.billing_business }}</div>
                <div>{{ quote.customer?.customer_name }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ quote.location?.location_address1 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const router = useRouter();

    const hidePending = ref(false);
    const hideAssigned = ref(false);
    const hideProcessed = ref(false);
    const hideReviewed = ref(false);
    const hideEmailed = ref(false);
    const sortBy = ref("sequence");
    const sortAsc = ref(true);

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, documents: quotes } = getCollection(
      `businesses/${business_id}/quotes`
    );

    watch(quotes, () => {
      if (quotes.value) {
        isLoading.value = false;
      }
    });

    watchEffect(() => {
      quotes.value?.sort((a, b) => {
        if (sortBy.value == "sequence" && sortAsc.value)
          return a.sequence_number - b.sequence_number;
        if (sortBy.value == "sequence" && !sortAsc.value)
          return b.sequence_number - a.sequence_number;
        if (sortBy.value !== "sequence" && sortAsc.value)
          return a.createdAt.seconds - b.createdAt.seconds;
        if (sortBy.value !== "sequence" && !sortAsc.value)
          return b.createdAt.seconds - a.createdAt.seconds;
      });
    });

    const filteredQuotes = computed(() => {
      return quotes.value?.filter((q) => {
        if (
          props.user.claims.user_role == "estimator" &&
          q.assigned_to !== props.user.uid
        ) {
          return false;
        }
        if (
          props.user.claims.user_role == "estimator" &&
          q.quote_status != "assigned" &&
          q.quote_status != "processed"
        ) {
          return false;
        }
        if (hidePending.value && q.quote_status == "pending") {
          return false;
        }
        if (hideAssigned.value && q.quote_status == "assigned") {
          return false;
        }
        if (hideReviewed.value && q.quote_status == "reviewed") {
          return false;
        }
        if (hideProcessed.value && q.quote_status == "processed") {
          return false;
        }
        if (hideEmailed.value && q.quote_status == "emailed") {
          return false;
        }
        return true;
      });
    });

    const { error: users_err, documents: users } = getCollection(
      `businesses/${business_id}/users`
    );

    const usernameFromId = (id) => {
      if (id && users.value) {
        const assigned = users.value.filter((u) => u.id == id).shift();
        return assigned.user_name;
      }
      return;
    };

    const goTo = (url) => {
      router.push(url);
    };

    const { fromSeconds } = useLuxon();

    return {
      isLoading,
      hidePending,
      hideAssigned,
      hideProcessed,
      hideReviewed,
      hideEmailed,
      sortBy,
      sortAsc,
      error,
      quotes,
      filteredQuotes,
      users_err,
      users,
      usernameFromId,
      goTo,
      fromSeconds,
    };
  },
};
</script>
